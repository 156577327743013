// imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useUserContext } from '../hooks/useUserContext';

// components
import Artist from "./Artist";

const TopArtists = ({ logout }) => {
    // states
    const [topArtists, setTopArtists] = useState([])
    const [topArtistsRange, setTopArtistsRange] = useState("medium_term")
    const [topArtistsN, setTopArtistsN] = useState("50")

    // user context
    const { user } = useUserContext()

    // use effect to assign tokens and request data
    useEffect(() => {
        if (user) {
            getTopArtists()
        }
        else {
            setTopArtists([])
        }
    }, [user, topArtistsRange, topArtistsN])

    // function for getting top artists
    const getTopArtists = async () => {
        console.log("Getting top artists")

        let allAudios = document.getElementsByTagName('audio')
        
        for (var i = 0; i < allAudios.length; i++) {
            let a_id = allAudios[i].id
            let a_button = document.getElementById(a_id.replace('prv','btn'))

            if (!allAudios[i].paused) {
                allAudios[i].pause()
                a_button.innerText = "Play Preview"
            }
        }

        const fetchArtists = async (time_range, limit, offset) => {
            let url = "https://api.spotify.com/v1/me/top/artists"
        
            try {
                let {data} = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`
                    },
                    params: {
                        time_range: time_range,
                        limit: limit,
                        offset: offset
                    }
                })

                return data.items
            }
            catch (ex) {
                console.log('Error getting top artists', ex)
                logout()
            }
        }

        let limit;

        if (topArtistsN == 100) {
            limit = 50
        } else {
            limit = topArtistsN
        }
        let offset = 0
        let time_range = topArtistsRange

        let top50 = await fetchArtists(time_range, limit, offset)
        setTopArtists(top50)

        if (topArtistsN == 100) {
            limit = 50
            offset = 50

            let next50 = await fetchArtists(time_range, limit, offset)
            setTopArtists([...top50, ...next50])
        }
    }

    return ( 
        <div className="top-artists top" id="top-artists">
            <div className="dropdowns">
                <select
                    name="top-artists-n"
                    id="top-artists-n"
                    value={topArtistsN}
                    onChange={(e)=>setTopArtistsN(e.target.value)}
                >
                    <option value="10">Top 10</option>
                    <option value="25">Top 25</option>
                    <option value="50">Top 50</option>
                    <option value="100">Top 100</option>
                </select>
                <select
                    name="top-artists-range"
                    id="top-artists-range"
                    value={topArtistsRange}
                    onChange={(e)=>setTopArtistsRange(e.target.value)}
                >
                    <option value="short_term">Last 4 weeks</option>
                    <option value="medium_term">Last 6 months</option>
                    <option value="long_term">All time</option>
                </select>
            </div>
            
            {topArtists && topArtists.map((artist, index) => (
                <Artist key={artist.id} artist={artist} index={index}/>
            ))}
        </div>
     );
}
 
export default TopArtists;