// imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useUserContext } from '../hooks/useUserContext'

// components
import Popularity from '../components/Popularity'
import Track from './Track'

const Artist = ({ artist, index }) => {
    // states
    const [artistTopTrack, setArtistTopTrack] = useState()

    // user context
    const { user } = useUserContext()

    // use effect to get artist top tracks
    useEffect(() => {
        if (user) {
            getArtistTopTracks()
        } else {
            setArtistTopTrack()
        }
    }, [user, artist])

    // function to get artists top track
    const getArtistTopTracks = async () => {
        let id = artist.id
        let market = user.country

        let url = `https://api.spotify.com/v1/artists/${id}/top-tracks?market=${market}`
        
        try {
            let {data} = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            })

            setArtistTopTrack(data.tracks[0])
        }
        catch (ex) {
            console.log('Error getting artists top track', ex)
        }
    }

    return (
        <div className="artist tile">
            <div className="rank">
                {index+1}
            </div>

            <div className="pic">
                {artist.images.length ? <img width={"100%"} src={artist.images[0].url} alt=""/> : <div>No Image</div>}
            </div>

            <div className="details">
                <div className="name">{artist.name}</div>
                <div className="artist-genres">
                    {artist.genres.map((g, index) => {
                        if (index !== 0) {
                            return `, ${g}`
                        }
                        else {
                            return g
                        }
                    })}
                </div>

                <div className="top-track">
                    {artistTopTrack ? 
                        <div>
                            <label>{artist.name.toUpperCase()}'S TOP TRACK</label>
                            <br/>
                            <Track track={artistTopTrack} index={0} plugin={true}/>
                        </div>
                        :
                        <div className="nothing"></div>
                    }
                </div>
            </div>

            <div className="bottom">
                <div className="bottom-item">
                    <div className="labeled-item">
                        <Popularity popularity={artist.popularity}/>
                    </div>
                    <label>POPULARITY</label>
                </div>

                <div className="bottom-item">
                    <div className="labeled-item">
                        <img src={`${process.env.PUBLIC_URL}/spotify-icon.svg`} alt="Open" className="icon clickable" onClick={() => window.open(artist.external_urls.spotify,"_blank")}/>
                    </div>
                    <label>LINK</label>
                </div>
            </div>
        </div>
     );
}
 
export default Artist;