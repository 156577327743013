const Login = () => {
    // constants for spotify sign in
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
    const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI
    const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT
    const RESPONSE_TYPE = process.env.REACT_APP_RESPONSE_TYPE
    const SCOPE = process.env.REACT_APP_SCOPE
    const SHOW_DIALOG = process.env.REACT_APP_SHOW_DIALOG

    // login function
    const login = () => {
        window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&show_dialog=${SHOW_DIALOG}`
    }

    return ( 
        <div id="login">
            <h1>Login to Spotify</h1>
            <p>Welcome to <strong>My Top Tunez</strong>!</p>
            <p>Login to your Spotify account to see up to your top 100 artists and tracks. Create playlists of all your favorite songs with a click!</p>
            <div className="login-button clickable" onClick={login}>
                <img src={`${process.env.PUBLIC_URL}/spotify-icon.svg`} className="icon" alt="Spotify"/>
                LOGIN
            </div>
        </div>
     );
}
 
export default Login;