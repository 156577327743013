// imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useUserContext } from '../hooks/useUserContext';

// components
import Track from "./Track";

const TopTracks = ({ logout }) => {
    // states
    const [topTracks, setTopTracks] = useState([])
    const [topTracksRange, setTopTracksRange] = useState("medium_term")
    const [topTracksN, setTopTracksN] = useState("50")
    const [playlist, setPlaylist] = useState(null)

    // user context
    const { user } = useUserContext()

    // use effect to assign tokens and request data
    useEffect(() => {
        if (user) {
            getTopTracks()
        }
        else {
            setTopTracks([])
        }
    }, [user, topTracksRange, topTracksN])

    // function for getting top tracks
    const getTopTracks = async () => {
        console.log("Getting top tracks")

        let allAudios = document.getElementsByTagName('audio')
        
        for (var i = 0; i < allAudios.length; i++) {
            let a_id = allAudios[i].id
            let a_button = document.getElementById(a_id.replace('prv','btn'))

            if (!allAudios[i].paused) {
                allAudios[i].pause()
                a_button.innerText = "Play Preview" 
            }
        }

        const fetchTracks = async (time_range, limit, offset) => {
            let url = "https://api.spotify.com/v1/me/top/tracks"
        
            try {
                let {data} = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`
                    },
                    params: {
                        time_range: time_range,
                        limit: limit,
                        offset: offset
                    }
                })

                return data.items
            }
            catch (ex) {
                console.log('Error getting top tracks', ex)
                logout()
            }
        }

        let limit;

        if (topTracksN == 100) {
            limit = 50
        } else {
            limit = topTracksN
        }
        let offset = 0
        let time_range = topTracksRange

        let top50 = await fetchTracks(time_range, limit, offset)
        setTopTracks(top50)

        if (topTracksN == 100) {
            limit = 50
            offset = 50

            let next50 = await fetchTracks(time_range, limit, offset)
            setTopTracks([...top50, ...next50])
        }
    }

    const addTracksToPlaylist = async (playlist_id) => {
        const url = `https://api.spotify.com/v1/playlists/${playlist_id}/tracks`

        const uris = []

        topTracks.forEach((track) => {
            uris.push(track.uri)
        })

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${user.access_token}`);

        const raw = JSON.stringify({
        "playlist_id": playlist_id,
        "uris": uris
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log(result)
        })
        .catch((error) => console.error(error));
    }

    const createPlaylist = async () => {
        let currentDate = new Date()
        let startDate = new Date()
        let name;
        let description;

        switch(topTracksRange) {
            case "short_term":
                startDate.setDate(currentDate.getDate() - 28);
                name = `My Top ${topTracksN} Tracks (${startDate.toLocaleDateString()} to ${currentDate.toLocaleDateString()})`
                description =   `Playlist created by MyTopTunez.com. My Top ${topTracksN} Tracks on Spotify over the last 4 weeks, as of ${currentDate.toLocaleDateString()}.`
                break
            case "medium_term":
                startDate.setMonth(currentDate.getMonth() - 6);
                name = `My Top ${topTracksN} Tracks (${startDate.toLocaleDateString()} to ${currentDate.toLocaleDateString()})`
                description =   `Playlist created by MyTopTunez.com. My Top ${topTracksN} Tracks on Spotify over the last 6 months, as of ${currentDate.toLocaleDateString()}.`
                break
            case "long_term":
                name = `My Top ${topTracksN} Tracks (${currentDate.toLocaleDateString()} All Time)`
                description =   `Playlist created by MyTopTunez.com. My Top ${topTracksN} Tracks on Spotify all time, as of ${currentDate.toLocaleDateString()}.`
                break
        }

        let url = `https://api.spotify.com/v1/users/${user.id}/playlists`
        
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${user.access_token}`);

            const raw = JSON.stringify({
            "name": name,
            "description": description,
            "public": false
            });

            const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
            };

            fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setPlaylist(result)
                const playlist_id = result.id

                addTracksToPlaylist(playlist_id)
            })
            .catch((error) => console.error(error));
        }
        catch (ex) {
            console.log('Error creating playlist', ex)
            logout()
        }
    }

    const handleClickClosePlaylist = () => {
        setPlaylist(null)
    }

    return ( 
        <div className="top-tracks top" id="top-tracks">
            <div className="dropdowns">
                <select
                    name="top-tracks-n"
                    id="top-tracks-n"
                    value={topTracksN}
                    onChange={(e)=>setTopTracksN(e.target.value)}
                >
                    <option value="10">Top 10</option>
                    <option value="25">Top 25</option>
                    <option value="50">Top 50</option>
                    <option value="100">Top 100</option>
                </select>
                <select
                    name="top-tracks-range"
                    id="top-tracks-range"
                    value={topTracksRange}
                    onChange={(e)=>setTopTracksRange(e.target.value)}
                >
                    <option value="short_term">Last 4 weeks</option>
                    <option value="medium_term">Last 6 months</option>
                    <option value="long_term">All time</option>
                </select>
            </div>

            <div>
                <div className="clickable" id="create-playlist-btn" onClick={createPlaylist}>
                    <img src={`${process.env.PUBLIC_URL}/spotify-icon.svg`} className="icon" alt="Spotify"/>
                    Create Playlist
                </div>
                {playlist &&
                    <div id="new-playlist">
                        <span id="new-playlist-close" onClick={handleClickClosePlaylist}>&times;</span>
                        <h3>New Playlist Created!</h3>
                        <div id="playlist-info">
                            <img src={`${process.env.PUBLIC_URL}/spotify-icon.svg`} alt="Open" className="icon clickable" onClick={() => window.open(playlist.external_urls.spotify,"_blank")}/>
                            <a href={playlist.external_urls.spotify} target="_blank">{playlist.name}</a>
                        </div>
                    </div>
                }
            </div>
           
            {topTracks && topTracks.map((track, index) => (
                <Track key={track.id} track={track} index={index} plugin={false}/>
            ))}
        </div>
     );
}
 
export default TopTracks;