import {useEffect, useState} from 'react'

const Popularity = ({ popularity }) => {
    const [color, setColor] = useState("")

    const getColor = () => {
        var hue=(((popularity/100))*120).toString(10)
        setColor(["hsl(",hue,",100%,50%)"].join(""))
    }

    useEffect(() => {
        getColor()
    },[popularity])

    return (
            <span style={{color: color}}>{popularity}</span>
     );
}
 
export default Popularity;