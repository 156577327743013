import { Link } from "react-router-dom";
import { useEffect } from "react";

const Privacy = () => {
    useEffect(() => {
        document.title = "My Top Tunez - Privacy Policy"
    },[])

    return ( 
        <div className="privacy">
            <div className="privacy-policy">
                <Link to="/">Back to Home</Link>

                <h1>Privacy Policy</h1>
                <p>This Privacy Policy explains what information My Top Tunez collects about its users, and what we do with that information. This policy applies only to data obtained through your use of MyTopTunez.com.</p>

                <h2>Your Data</h2>
                <p>My Top Tunez only processes data that is necessary for providing you with our service. We do not share your data with anyone, and do not store any of your data for any period of time.</p>
                <p>My Top Tunez is a front-end web application designed to display information supplied from the Spotify Web API. By using this service, you are also bound by <a href="https://www.spotify.com/us/legal/privacy-policy/" target="_blank" rel="noreferrer">Spotify's Privacy Policy</a>. When you connect your Spotify account, a request is made from your browser to Spotify to obtain your username and access to your Spotify activity. This request and any further requests are made from the browser and do not pass through the servers hosting My Top Tunez.</p>

                <h2>Cookies</h2>
                <p>A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. My Top Tunez does not use any cookies itself. In line with Spotify's Privacy Policy, we do allow third parties to place Cookies on visitor browsers in order to collect information about browsing activities. These are managed by Spotify and are not in the control of My Top Tunez.</p>
        
                <h2>Contact</h2>
                <p>If you would like to get in touch with us about our privacy policy or otherwise, please send an email to UseMyTopTunez@gmail.com.</p>
            </div>
        </div>
     );
}
 
export default Privacy;