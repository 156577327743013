// components
import Popularity from '../components/Popularity'

const Track = ({ track, index, plugin }) => {
    
    const togglePreview = () => {
        let audio = document.getElementById(`${plugin ? 'plug' : ''}prv${track.id}`)
        let button = document.getElementById(`${plugin ? 'plug' : ''}btn${track.id}`)

        let allAudios = document.getElementsByTagName('audio')
        
        for (var i = 0; i < allAudios.length; i++) {
            let a_id = allAudios[i].id
            let a_button = document.getElementById(a_id.replace('prv','btn'))

            if (!allAudios[i].paused && allAudios[i].id !== `${plugin ? 'plug' : ''}prv${track.id}`) {
                allAudios[i].pause()
                a_button.innerText = "play_circle"
            }
        }

        if (audio.paused) {
            audio.volume = 0.2;
            audio.play();
            button.innerText = "pause_circle"        
          } else {
            audio.pause();
            button.innerText = "play_circle"
          }
    }

    const handleAudioEnded = (e) => {
        let audioId = e.target.id
        let button = document.getElementById(audioId.replace('prv','btn'))

        e.target.pause();
        e.target.currentTime = 0;

        button.innerText = "play_circle"
    }

    return ( 
        <div className={`track tile ${plugin ? 'plug-in' : ''}`}>
            {!plugin ? 
                <div className="rank">
                    {index+1}
                </div>
                :
                <div className="nothing"></div>
            }
            
            <div className="pic">
                {track.album.images.length ? <img width={"100%"} src={track.album.images[0].url} alt=""/> : <div>No Image</div>}
            </div>
            
            <div className="details">
                <div className="name">{track.name}</div>
                <div className="track-artists">
                    {track.artists.map((a, index) => {
                        if (index !== 0) {
                            return `, ${a.name}`
                        }
                        else {
                            return a.name
                        }
                    })}
                </div>
            </div>

            <div className="details-hidden">
                <div className="name">{track.name}</div>
                <div className="track-artists">
                    {track.artists.map((a, index) => {
                        if (index !== 0) {
                            return `, ${a.name}`
                        }
                        else {
                            return a.name
                        }
                    })}
                </div>
            </div>
            
            <div className="bottom">
                <div className="bottom-item">
                    <div className="labeled-item">
                        <Popularity popularity={track.popularity}/>
                    </div>
                    {!plugin ? 
                        <label>POPULARITY</label>
                        :
                        <div className="nothing"></div>
                    }
                </div>

                <div className="bottom-item">
                    <div className="labeled-item">
                        <img src={`${process.env.PUBLIC_URL}/spotify-icon.svg`} alt="Open" className="icon clickable" onClick={() => window.open(track.external_urls.spotify,"_blank")}/>
                    </div>
                    {!plugin ? 
                        <label>LINK</label>
                        :
                        <div className="nothing"></div>
                    }
                </div>

                <div className="bottom-item">
                    <div className="labeled-item">
                        <span className="material-symbols-outlined icon clickable" id={`${plugin ? 'plug' : ''}btn${track.id}`} onClick={togglePreview}>
                            play_circle
                        </span>
                    </div>
                    {!plugin ? 
                        <label>PREVIEW</label>
                        :
                        <div className="nothing"></div>
                    }
                </div>
                 
                <audio id={`${plugin ? 'plug' : ''}prv${track.id}`} src={track.preview_url} onEnded={handleAudioEnded}></audio>
            </div>
        </div>
     );
}
 
export default Track;