import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return ( 
        <footer>
            <div className="copyright">
                &copy; 2023 Ben Kisow
            </div>
            <div className="links">
                <Link to='/'>Home</Link>
                <Link to='/privacy'>Privacy Policy</Link>
            </div>
        </footer>
     );
}
 
export default Footer;