// imports
import { useUserContext } from "../hooks/useUserContext";
import { useState, useEffect } from "react";

const Header = () => {
    // user context
    const { user, dispatch } = useUserContext()
    // states
    const [hideUserName, setHideUserName] = useState(false)

    useEffect(() => {
        mediaQuery()
    },[hideUserName])

    // logout function
    const logout = () => {
        dispatch({type: 'LOGOUT'})
        localStorage.removeItem("token")
    }

    const switchAccount = () => {
        const logoutWindow = window.open("https://accounts.spotify.com/logout", "_blank")
        setTimeout(() => logoutWindow.close(), 500)

        logout()
    }

    const openAccountMenu = () => {
        document.getElementById("account-menu").style.display = "block"
        document.getElementById("click-stopper").style.display = "block"
        if (!hideUserName) {
            document.getElementById("user-name").style.display = "none"
            document.getElementsByClassName("profile-pic")[0].style.display = "none"
        }
    }

    const closeAccountMenu = () => {
        document.getElementById("account-menu").style.display = "none"
        document.getElementById("click-stopper").style.display = "none"
        if (!hideUserName) {
            document.getElementById("user-name").style.display = "block"
            document.getElementsByClassName("profile-pic")[0].style.display = "block"
        }
    }

    const hideUserNameQ = window.matchMedia("(max-width: 560px)")

    hideUserNameQ.addEventListener("change", function() {
        mediaQuery()
    })

    const mediaQuery = () => {
        if (hideUserNameQ.matches) {
            setHideUserName(true)
        } else  {
            setHideUserName(false)
        }
    }

    return ( 
        <header>
            <div id="title-div">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt=""/>
                <div id="title">My Top Tunez</div>
            </div>
            {user ?
                <div className="logout">
                    {!hideUserName && 
                        <div id="user-name" onClick={openAccountMenu}>{user.display_name}</div>
                    }
                    {user.images.length ?
                        <img className="profile-pic" onClick={openAccountMenu} width={"100%"} src={user.images[0].url} alt=""/>
                        :
                        <img className="profile-pic" onClick={openAccountMenu} src={`${process.env.PUBLIC_URL}/default-profile-pic.jfif`} />
                    }
                    <div id="account-menu">
                        <div id="account-menu-close">
                            <span class="material-symbols-outlined" onClick={closeAccountMenu}>
                                expand_circle_right
                            </span>
                        </div>
                        <div id="user-welcome">
                            <span>Hi, <strong>{user.display_name}</strong>!</span>
                            {user.images.length ?
                                <img className="profile-pic" width={"100%"} src={user.images[0].url} alt=""/>
                                :
                                <img className="profile-pic" src={`${process.env.PUBLIC_URL}/default-profile-pic.jfif`} />
                            }
                        </div>
                        <div className="account-menu-option" onClick={() => window.open(user.external_urls.spotify,"_blank")}>View Account</div>
                        <div className="account-menu-option" onClick={switchAccount}>Switch Account</div>
                        <div className="account-menu-option" onClick={logout}>Logout</div>
                    </div>
                </div>
                :
                <div className="nothing"></div>
            }
        </header>
     );
}
 
export default Header;