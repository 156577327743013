// imports
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useUserContext } from '../hooks/useUserContext'

// components
import TopArtists from "../components/TopArtists"
import TopTracks from "../components/TopTracks"
import Login from '../components/Login'
import Track from '../components/Track'

const Home = () => {
    // constants
    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

    // user context
    const { user, dispatch } = useUserContext()

    // states
    const [showToTop, setShowToTop] = useState(false)

    // logout function
    const logout = () => {
        dispatch({type: 'LOGOUT'})
        window.localStorage.removeItem("token")
    }

    // use effect to assign user
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        const getToken = () => {
            const hash = window.location.hash
            let localToken = window.localStorage.getItem("token")
        
            if (!localToken && hash) {
                localToken = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

                //console.log(localToken)
        
                window.location.hash = ""
                window.localStorage.setItem("token", localToken)

                const logoutTimeout = () => {
                    setTimeout(logout, 3480000) // logout after 58 minutes
                }

                logoutTimeout()
            }

            const getUserProfile = async () => {
                let url = "https://api.spotify.com/v1/me"
                
                try {
                    let {data} = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${localToken}`
                        }
                    })
                    
                    data["access_token"] = localToken
                    //console.log('User data:',data)

                    //setUserName(data.display_name)
                    dispatch({type: 'LOGIN', payload: data})
                }
                catch (ex) {
                    console.log('Error getting user profile', ex)
                    logout()
                }
            }

            if (localToken) {
                getUserProfile()
            }
        }

        getToken()

        return () =>
            window.removeEventListener("scroll", handleScroll);
    }, [dispatch])

    const handleScroll = () => {
        let heightToShowFrom = 300;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > heightToShowFrom) {
            setShowToTop(true);
        } else {
            setShowToTop(false);
        }
    }

    // toggle between top artists and top tracks
    const toggleTopTabs = ( chosen ) => {
        const content = document.getElementById("content")

        switch (chosen) {
            case 'artists':
                var showDiv = document.getElementById("top-artists")
                var hideDiv = document.getElementById("top-tracks")
                var showTab = document.getElementById("artist-tab")
                var hideTab = document.getElementById("track-tab")

                content.style.borderTopRightRadius = "0"
                content.style.borderTopLeftRadius = "8px"
                break
            case 'tracks':
                var hideDiv = document.getElementById("top-artists")
                var showDiv = document.getElementById("top-tracks")
                var hideTab = document.getElementById("artist-tab")
                var showTab = document.getElementById("track-tab")

                content.style.borderTopLeftRadius = "0"
                content.style.borderTopRightRadius = "8px"
                break
            default:
                console.log('invalid input for toggleTopTabs')
                break
        }

        if (!showTab.classList.contains("selected")) {
            let allAudios = document.getElementsByTagName('audio')
        
            for (var i = 0; i < allAudios.length; i++) {
                let id = allAudios[i].id
                let button = document.getElementById(id.replace('prv','btn'))

                if (!allAudios[i].paused) {
                    allAudios[i].pause()
                    button.innerText = "play_circle"
                }
            }
        }

        showTab.classList.add("selected")
        hideTab.classList.remove("selected")
        hideTab.classList.add("clickable")
        showTab.classList.remove("clickable")

        hideDiv.style.display = "none"
        showDiv.style.display = "block"
    }

    return ( 
        <div className="home">
            <div id="click-stopper"></div>
            {!user ?
                <Login />
                :
                <div className="nothing"></div>
            }

            {user && showToTop &&
                <div id="scroll-to-top" onClick={()=>{window.scrollTo(0,0)}}>Scroll to Top</div>
            }

            <div className={`content-container ${!user ? 'is-blurred' : ''}`}>
                <div className="tabs">
                    <div id="track-tab" onClick={()=>{toggleTopTabs('tracks')}} className="tab selected">My Top Tracks</div>
                    <div id="artist-tab" onClick={()=>{toggleTopTabs('artists')}} className="tab clickable">My Top Artists</div>
                </div>
                <div className="content" id="content">
                    <TopArtists logout={logout}/>
                    <TopTracks logout={logout}/>
                </div>
            </div>

            {ENVIRONMENT === "DEV" ? 
                <div className="environment">
                    {ENVIRONMENT}
                </div>
                :
                <div className="nothing"></div>
            }
            
        </div>
     );
}
 
export default Home;